import { connect } from 'react-redux'
import Button from '../../Button'
import MetaMaskOnboarding from '@metamask/onboarding'
import { useEffect, useRef } from 'react'

const InstallMetamask = props => {
  const onboarding = useRef()

  useEffect(_ => {
    onboarding.current = new MetaMaskOnboarding()
    return _ => onboarding.current.stopOnboarding()
  }, [])

  return (
    <>
      <h1 className='font-display text-3xl font-semibold text-gray-700'>Before you start</h1>
      <p className='font-medium text-gray-600'>MetaMask provides the simplest yet most secure way to connect to blockchain-based applications, such as The Crypt.</p>
      <Button className='w-full' onClick={_ => onboarding.current.startOnboarding()}>
        Install Metamask
      </Button>
      <p className='text-xs'>
        <a className='underline' href='#' onClick={_ => window && window.Beacon && window.Beacon('article', '60f6cc4cb37d837a3d0db6cc', { type: 'sidebar' })}>
          What's a wallet?
        </a>
      </p>
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({ ...ownProps })
export default connect(mapStateToProps, {})(InstallMetamask)
