import Button from '../../Button'
import { useState } from 'react'
import { Switch } from '@headlessui/react'
import { Fragment } from 'react'
import { useAudioInputDevices, useAudioOutputDevices } from '../../MediaDeviceListBox'
import AudioInputList from '../../AudioInputList'
import AudioOutputList from '../../AudioOutputList'

const Settings = ({ group }) => {

  const [enabled, setEnabled] = useState(true)
  const [inputDevices, selectedInputDevice, selectInputDevice] = useAudioInputDevices()
  const [outputDevices, selectedOutputDevice, selectOutputDevice] = useAudioOutputDevices()
  
  return (
    <div className='flex flex-col text-left p-8 w-full space-y-8'>
      <div>
        {/* <span className='text-red-300 flex items-center font-display text-sm uppercase space-x-2'><ArrowLeftIcon className='w-4 h-4'/></span> */}
        <h1 className='font-display text-3xl font-semibold text-gray-600'>Settings</h1>
      </div>
      <AudioInputList />
      <AudioOutputList />
      <div className='flex space-x-6'>
        <div>
          <h1 className='font-medium'>Use spatial sound?</h1>
          <p className='text-gray-400 font-normal text-sm'>When enabled group audio will be spatialized. Disabling can improve the sound quality</p>
        </div>
        <Switch checked={enabled} onChange={setEnabled}
          className={`${enabled ? 'bg-gray-100' : 'bg-gray-100'} relative inline-flex flex-shrink-0 h-6 w-12 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`} >
          <span aria-hidden="true"
            className={`${enabled ? 'translate-x-6 bg-red-300' : 'translate-x-0 bg-gray-300'} pointer-events-none inline-block h-6 w-6 rounded-full shadow-lg transform ring-0 transition ease-in-out duration-200`}
          />
      </Switch>
      </div>
    </div>
  )
}

export default Settings
