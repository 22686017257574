import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Fragment } from 'react'

const ListBox = ({ items, selectedItem = _ => _, setSelected }) => {
  return (
    <Listbox value={selectedItem} onChange={items && items.length && setSelected}>
      <div className='relative mt-1 w-full select-none'>
        <Listbox.Button className='flex items-center w-full py-4 pl-3 pr-10 rounded-lg border border-gray-200 cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm'>
          <span className='text-left flex-grow truncate font-detail text-gray-400'>{selectedItem ? selectedItem.label : ''}</span>
          <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
            <SelectorIcon className='w-5 h-5 text-gray-300' aria-hidden='true'/>
          </span>
        </Listbox.Button>
        { items && items.length > 0 && <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <Listbox.Options className='absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-md max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            {Array.from(items).map((item, i) => (
              <Listbox.Option
                key={i}
                value={item}
                className={({ active }) => `${active ? 'text-red-900 bg-red-100' : 'text-gray-600'} cursor-default select-none relative py-3 pl-10 pr-4` }>
                {({ selected, active }) => <>
                  <span className={`${selected ? 'font-medium' : 'font-normal'} block font-detail truncate`}>{item.label}</span>
                  {selected ? <span className={`${active ? 'text-red-400' : 'text-red-300'} absolute inset-y-0 left-0 flex items-center pl-3`}>
                    <CheckIcon className='w-5 h-5' aria-hidden='true' />
                  </span> : null}
                </>}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition> }
      </div>
    </Listbox>
  )
}

export default ListBox