import { useEffect, useRef } from 'react'
import throttle from 'lodash.throttle'
import Image from 'next/image'

const MoveAround = ({ onComplete, isHovering }) => {
  const svgRef = useRef()
  const distanceRef = useRef(0)
  const progressActualRef = useRef(0)
  const progressTargetRef = useRef(0)
  const reqAnimRef = useRef()
  const radius = 48

  useEffect(_ => {
    const step = _ => {
      progressActualRef.current += (progressTargetRef.current - progressActualRef.current) * 0.2
      if (svgRef.current) svgRef.current.style.strokeDashoffset = 2 * Math.PI * radius * (1 - progressActualRef.current)
      reqAnimRef.current = window.requestAnimationFrame(step)
    }

    step()
    return _ => window.cancelAnimationFrame(reqAnimRef.current)
  }, [])

  useEffect(_ => {
    // accumulate the distance
    // let distance = 0

    // threshold is proportional to available screen size
    const threshold = Math.hypot(window.screen.width, window.screen.height) * 0.5

    const onMouseMove = throttle(e => {
      if (isHovering) return
      distanceRef.current += Math.hypot(e.movementX, e.movementY)
      progressTargetRef.current = Math.max(0, Math.min(1, distanceRef.current / threshold))

      if (Math.abs(1 - progressTargetRef.current) < 0.001) {
        document.removeEventListener('mousemove', onMouseMove)
        onComplete()
      }
    }, 32)

    document.addEventListener('mousemove', onMouseMove)
    return _ => document.removeEventListener('mousemove', onMouseMove)
  }, [isHovering])

  return (
    <>
      <div className='relative flex items-center text-center '>
        {/* <div className='absolute inset-0 relative w-full h-5/6 transform translate-y-5'>
        </div> */}
        <div className='absolute inset-6 transform translate-y-3'>
          <Image layout='fill' src='/images/gameboy-colour.svg' />
        </div>
        <svg width='18rem' className='relative' viewBox='0 0 100 100'>
          <g transform='translate(0, 50) rotate(-90, 25, 25 )'>
            <circle className='stroke-current text-gray-100' fill='none' cx='50' cy='50' r={radius} strokeWidth='4' />
            <circle ref={svgRef} className='stroke-current text-red-300' fill='none' strokeDasharray={2 * Math.PI * radius} cx='50' cy='50' r={radius} strokeWidth='4' />
          </g>
        </svg>
      </div>
      <h1 className='font-display pt-8 text-3xl font-semibold text-gray-700'>How do I move around?</h1>
      <p>You can look around by moving your mouse in the viewport.</p>
      <p>Give it a go now.</p>
    </>
  )
}

MoveAround.defaultProps = {
  onComplete: _ => console.log('onComplete is not overridden'),
  isHovering: false
}

MoveAround.hasCompletedUserAction = false

export default MoveAround
