const Loader = ({ progress, children }) => (
  <div className='w-full space-y-2 select-none'>
    {children}
    <div className='h-px relative max-w-xl overflow-hidden'>
      <div className='w-full h-full bg-gray-200 absolute' />
      <div className='h-full bg-red-300 absolute' style={{ width: progress * 100 + '%' }} />
    </div>
  </div>
)

export default Loader
