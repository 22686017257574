import Image from 'next/image'

const OnboardingSuccess = ({ onComplete }) => {
  return (
    <>
      <div className='relative w-full h-1/3'>
        <Image layout='fill' src='/images/a-ok-colour.svg' />
      </div>
      <h1 className='font-display text-3xl font-semibold text-gray-700'>Achievement Unlocked!</h1>
      <p>Congatulations, you’re ready to start exploring the Crypt.</p>
      <p>You can always come back by hitting the Help item above </p>
    </>
  )
}

OnboardingSuccess.hasCompletedUserAction = false

export default OnboardingSuccess
