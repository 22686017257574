import { forwardRef } from 'react'

const Popout = forwardRef(({ children, className, isHovering }, ref) => {
  return (
    <div ref={ref} className={'bg-gray-50 text-gray-900 self-center h-160 md:rounded md:shadow ' + className}>
      {
        children
      }
    </div>
  )
})

export default Popout
