import { connect } from 'react-redux'
import Popout from '../Popout'
import { CgClose } from 'react-icons/cg'
import { closePopout } from '../../redux/site'
import { forwardRef, useState } from 'react'
import CreateGroup from './states/CreateGroup'
import GroupCall from './states/GroupCall'
import usePermissions from '../../hooks/device/usePermissions'
import RequestPermissions from './states/RequestPermissions'
import Settings from './states/Settings'
import LeftGroup from './states/LeftGroup'
import { joinGroup, leaveGroup } from '../../redux/groups'
import InviteGroup from './states/InviteGroup'
import { ArrowLeftIcon } from '@heroicons/react/solid'
import useForceUpdate from '../../hooks/useForceUpdate'

const Group = ({ auth, group, closePopout, leaveGroup, joinGroup, forwardRef }) => {
  const [panel, setPanel] = useState()
  const [leftGroup, setLeftGroup] = useState(false)
  const forceUpdate = useForceUpdate()
  const permission = usePermissions('microphone')

  const requestLeaveGroup = _ => {
    leaveGroup()
    setLeftGroup(group)
  }

  const requestRejoinGroup = async _ => {
    await joinGroup(leftGroup)
    setLeftGroup(null)
  }

    console.log(permission)

  const onCloseRequest = (panel === 'settings' || panel === 'invite') 
    ? _ => setPanel(null)
    : _ => closePopout()

  return (
    <Popout className='flex relative pointer-events-auto flex-col justify-center items-center text-center space-y-6 w-full h-screen md:w-112 md:h-152 lg:w-128 lg:h-160 max-h-screen' ref={forwardRef}>
      <CgClose size='1.5rem' onClick={onCloseRequest} className='absolute top-6 right-6 md:top-4 md:right-4 cursor-pointer text-red-300 hover:text-red-400' />
      {
        ((permission === 'prompt' || permission === 'denied') && <RequestPermissions onPermissionsGranted={forceUpdate} />) ||
        (panel === 'settings' && <Settings />) ||
        (panel === 'invite' && <InviteGroup group={group} />) ||
        (leftGroup && <LeftGroup group={leftGroup} onRequestRejoinGroup={requestRejoinGroup} onRequestCreateGroup={_ => setLeftGroup(false)} />) ||
        (group && <GroupCall group={group} onRequestInviteUser={_ => setPanel('invite')} onRequestLeaveGroup={requestLeaveGroup} onRequestSettings={_ => setPanel('settings')} />) ||
          <CreateGroup />
      }
    </Popout>
  )
}

const mapStateToProps = ({ auth, groups }, ownProps) => ({ ...ownProps, auth, group: groups.group })
const ConnectedGroup = connect(mapStateToProps, { closePopout, leaveGroup, joinGroup })(Group)
export default forwardRef((props, ref) => <ConnectedGroup {...props} forwardRef={ref} />)
