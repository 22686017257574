import { connect, useDispatch } from 'react-redux'
import Button from '../../Button'
import { closePopout } from '../../../redux/site'
import Lottie from 'react-lottie'
import useSWR from 'swr'

const AuthSuccess = ({ auth }) => {
  const dispatch = useDispatch()
  const onStartExploreClick = _ => dispatch(closePopout())

  const { data: animationData } = useSWR('/animations/34115-rocket-launch.json')

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <>
      <div className='w-4/5 mx-auto'>{animationData && <Lottie options={defaultOptions} isClickToPauseDisabled width='auto' height='20rem' />}</div>
      <h1 className='font-display text-3xl font-semibold text-gray-700'>You're all set!</h1>
      <div className='space-y-2'>
        <p>Congratulations and welcome to the fam.</p>
        {!auth.user.emailVerified && <p className='font-medium'>Remember you need to verify your email</p>}
      </div>

      <Button className='w-full max-w-xs' onClick={onStartExploreClick}>
        Start Exploring
      </Button>
    </>
  )
}

const mapStateToProps = ({ auth }, ownProps) => ({ ...ownProps, auth })
export default connect(mapStateToProps, {})(AuthSuccess)
