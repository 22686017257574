import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import Button from '../../Button'
import { register } from '../../../redux/auth'
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore'

const db = getFirestore()
const col = collection(db, 'users')

// const AccountExists = connec({ account }) => {

//     const dispatch = useDispatch()
//     const onRequestAuthenticate = _ => dispatch(authenticate())

//     return <>
//         <h1 className='font-display text-3xl font-semibold text-gray-700'>Sign In!</h1>
//         <p>There's already an account with this address. You probably want to sign in instead </p>
//         <Button className='w-full' onClick={onRequestAuthenticate}>Sign In</Button>
//     </>
// }

const CaptureEmailUsername = ({ register: registerUser, account }) => {
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange' })

  const checkIsUsernameAvailable = async username => {
    const q = query(col, where('username', '==', username.toLowerCase()))
    const { empty } = await getDocs(q)
    return empty
  }

  const onSubmit = ({ username, email }) => registerUser({ username, email })

  return (
    <>
      {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-8'>
        <h1 className='font-display text-3xl font-semibold text-gray-700'>One more thing...</h1>
        <p>Grab a unique username and drop your email. You'll be able to start bidding and creating your own collection immediately.</p>
        <div className='text-left'>
          {/* register your input into the hook by invoking the "register" function */}
          <input
            type='text'
            className={`rounded w-full ${errors.username ? 'border-red-700' : 'border-gray-200'}`}
            placeholder='Username'
            {...register('username', {
              required: true,
              minLength: 4,
              maxLength: 15,
              pattern: /^[a-zA-Z0-9-_]+$/,
              validate: checkIsUsernameAvailable
            })}
          />
          {errors.username && errors.username.type === 'required' && <p className='text-red-700 text-xs'>A Username is required</p>}
          {errors.username && errors.username.type === 'validate' && <p className='text-red-700 text-xs'>This username is already taken</p>}
          {errors.username && errors.username.type === 'pattern' && <p className='text-red-700 text-xs'>Only contain numbers, letters and an underscore</p>}
          {errors.username && errors.username.type === 'maxLength' && <p className='text-red-700 text-xs'>Must be less than 15 characters</p>}
          {errors.username && errors.username.type === 'minLength' && <p className='text-red-700 text-xs'>Must be more than 4 characters</p>}
        </div>

        {/* include validation with required or other standard HTML validation rules */}
        <div className='text-left'>
          <input type='email' className={`rounded w-full ${errors.email ? 'border-red-700' : 'border-gray-200'}`} placeholder='Email' {...register('email', { required: true, pattern: /^\S+@\S+$/i })} />
          {/* errors will return when field validation fails  */}
          {errors.email && errors.email.type === 'required' && <p className='text-red-700 text-xs'>This field is required</p>}
          {errors.email && errors.email.type === 'pattern' && <p className='text-red-700 text-xs'>This isn't a valid email</p>}
        </div>

        <Button className='w-full' onClick={handleSubmit(onSubmit)}>
          Sign with Metamask
        </Button>
        <p className='text-xs font-detail'>
          By signing up you are accepting our{' '}
          <a className='underline' target='_blank' rel='noreferrer' href='https://festive-snap-bc7.notion.site/Virtually-Galleries-Limited-Terms-of-use-b70c4398574147319075f44a02083b56'>
            terms and conditions
          </a>{' '}
          and{' '}
          <a className='underline' target='_blank' rel='noreferrer' href='https://festive-snap-bc7.notion.site/Virtual-Galleries-Limited-Privacy-Policy-5266cb0305dd404e97b7bf6839105532'>
            privacy policy
          </a>
        </p>
      </form>
    </>
  )
}

const mapstateToProps = ({ auth }, ownProps) => ({ ...ownProps, account: auth.account })
export default connect(mapstateToProps, { register })(CaptureEmailUsername)
