import Button from '../../Button'
import Image from 'next/image'
import { toast } from 'react-toastify'

const InviteGroup = ({ group }) => {
  const share = _ => {
    if (navigator.share) {
      navigator.share({
        title: 'The Crypt',
        text: 'Hey, join me @The Crypt',
        url: `https://${window.location.host}/join/${group}`
      })
    } else {
      navigator.clipboard.writeText(`${window.location.host}/join/${group}`)
      toast('Copied Group Link!')
    }
  }

  return (
    <div className='space-y-6 p-6 md:px-8 md:py-10'>
      <div className='mx-auto relative w-10/12 h-72'>
        <Image layout='fill' objectFit='cover' src='/images/nature-man-colour.svg' />
      </div>
      <h1 className='font-display text-3xl font-semibold'>Invite some people</h1>
      <div className='space-y-2'>
        <p className='font-medium'>Invite up to 5 people to your group by sharing the link below.</p>
        <p className='font-sm'>Once they join you’ll be able to explore The Crypt with them</p>
      </div>

      <div className='flex items-center rounded p-2'>
        <span className='flex-grow border-t border-l border-b text-sm border-gray-300 rounded-lg p-2.5'>{window && `${window.location.host}/join/${group}`}</span>
        <Button className='transform -translate-x-2 rounded-r-lg rounded-l-none border border-red-300' onClick={share}>Share</Button>
      </div>

    </div>
  )
}

export default InviteGroup
