import { useEffect, useMemo, useRef } from 'react'
import { useEngineEvent } from '../game-engine/useEngine'
import jazzicon from '@metamask/jazzicon'
import { child, getDatabase, onChildAdded, onChildChanged, onChildRemoved, onDisconnect, onValue, push, ref, set, update } from '@firebase/database'
import { throttle } from 'lodash'
import firebaseApp from '../../utils/firebase'
import { getAuth, signInAnonymously } from '@firebase/auth'
import { query } from '@firebase/firestore'

const realtimeDb = getDatabase(firebaseApp)
const TEX_CACHE = {}

const createUserTexture = (engine, uid, size = 512) => {
  if (!window.pc) throw new Error('engine has not loaded')

  if(TEX_CACHE[uid]) return TEX_CACHE[uid]

  const addr = uid.slice(2, 10)
  const seed = parseInt(addr, 16)
  const icon = jazzicon(size, Math.round(seed))
    .innerHTML.split('<svg').join('<svg xmlns="http://www.w3.org/2000/svg"')

  const blob = new window.Blob([icon], {type: 'image/svg+xml'})
  const url = URL.createObjectURL(blob)
  const image = document.createElement('img')
  const texture = new pc.Texture(engine.graphicsDevice, {
    width: size,
    height: size,
    format: window.pc.PIXELFORMAT_R8_G8_B8
  })

  image.addEventListener('load', _ => {
    texture.setSource(image)
    URL.revokeObjectURL(url)
  })

  image.src = url

  TEX_CACHE[uid] = texture

  return texture
}

const lerp = (lhs, rhs, alpha) => ([
  lhs.x + alpha * (rhs[0] - lhs.x),
  lhs.y + alpha * (rhs[1] - lhs.y),
  lhs.z + alpha * (rhs[2] - lhs.z)
])

const addPlayerAsset = (engine, { id, username }, template, root) => {
  console.log('player template', template, username)
  if (!engine.root.findByName('RemotePlayerLabelUI')) {
    const labelTemplate = engine.assets.find('RemotePlayerLabelUI')
    console.log('label template', labelTemplate)
    const labelRoot = labelTemplate.resource.instantiate()
    labelRoot.name = 'RemotePlayerLabelUI'
    root.addChild(labelRoot)
  }
  
  const instance = template.resource.instantiate()
  instance.username = username
  instance.name = id
  const texture = createUserTexture(engine, id)
  instance.uidTexture = texture
  root.addChild(instance)
  return instance
}

const addPlayer = (engine, user) => {
  const playerStateRef = ref(realtimeDb, `/mm/${user.uid}`)
  console.log('New Remote Player', user.uid)
  // Listen for state updates to this new participant
  const unsubscribePlayerState = onValue(playerStateRef, doc => {
    if (doc.exists()) {
      const state = doc.val()
      engine.fire(`player:update:${user.uid}`, doc => doc.val())
      engine.fire('player:update', { id: user.uid, state })
    }
  })

  // Notify the engine of the new participant
  engine.fire('player:add', { id: user.uid, username: user ? user.displayName : '' })

  // If we recieve an remove event then unsubscribe from states updates
  engine.once(`player:remove:${user.uid}`, _ => unsubscribePlayerState())
}

const removePlayer = (engine, user) => {
  console.log('Player left')
  engine.fire(`player:remove:${user.uid}`)
  engine.fire('player:remove', { id: user.uid })
}

export const useMultiplayer = (engine, user, sessionID, {
  templateName = 'Remote Player Template',
  rootName = 'Players' } = {}) => {

  const players = useRef({})
  const root = useMemo(_ => {
    if (engine) {
      console.log('rootName rootName rootName', rootName)
      let root = engine.root.findByName(rootName)
      if (!root) {
        root = new window.pc.Entity()
        root.name = rootName
        engine.root.addChild(root)
      }
      return root
    }
  }, [engine])

  useEngineEvent(engine, 'player:add', ({ id, username }) => {
    const template = engine.assets.find(templateName)
    if (!template) throw new Error(`No template '${templateName}' found in the asset registry`)

    const player = addPlayerAsset(engine, { id, username }, template, root)
    console.log('HAS POSITION', player.getPosition())
    console.log('player:add', id, template, root, player)
    players.current[id] = player
  })

  useEngineEvent(engine, 'player:remove', ({ id }) => {
    const player = players.current[id]
    console.log('player:remove')
    if (player) {
      player.uidTexture.destroy()
      player.destroy()
      root.removeChild(player)
      delete players.current[id]
    }
  })

  useEngineEvent(engine, 'player:update', ({ id, state }) => {
    const player = players.current[id]
    const { position, rotation, username } = state
    // console.log('player:update', position, rotastion )
    if (player) {
      player.fire('updateUserName', username)
      player._targetPosition = position
      player._targetRotation = rotation
    }
  })

  // const { uid, displayName } = user// getUser(auth)//.then(({ uid, displayName }) => {

  useEngineEvent(engine, 'player:broadcast', throttle(updates => {
    // console.log(updates)
    const meStateRef = ref(realtimeDb, `/mm/${sessionID}`)
    update(meStateRef, { ...updates, username: (user && user !== 'unknown') ? user.displayName : '' })
  }, 1000 / 10), [user])

  if (sessionID) onDisconnect(ref(realtimeDb, `/mm/${sessionID}`)).remove()

  useEffect(_ => {
    if (!engine) return
    const mmRef = ref(realtimeDb, '/mm')

    console.log('adding child added listener', engine, sessionID)

    const unsubOnChildAdded = onChildAdded(mmRef, data => {
      const newUser = data.val()
      const newUserSessionId = data.key
      if (newUserSessionId === (newUser && String(sessionID))) return // this is me
      // console.log('adding', userSessionId, user.uid)
      if (!root.findByName(newUserSessionId)) addPlayer(engine, { ...newUser, uid: newUserSessionId })
    })

    const unsubOnChildRemove = onChildRemoved(mmRef, data => {
      const removedUser = data.val()
      const removedUserSessionID = data.key
      if (root.findByName(removedUserSessionID)) removePlayer(engine, { ...removedUser, uid: removedUserSessionID })
    })

    return _ => {
      if (engine) {
        unsubOnChildRemove()
        unsubOnChildAdded()
      }
    }
  }, [engine, sessionID])

  const ID = useRef(null)
  useEffect(_ => {
    const step = _ => {
      const playersEntitys = Object.values(players.current)

      playersEntitys.forEach(player => {
        if (player._targetPosition && player._targetRotation) {
          // smooth lerp the position
          const position = lerp(player.getPosition(), player._targetPosition, 0.1)
          player.setPosition(...position)
          // Smooth lerp the rotation
          const rotation = player.getRotation()
          const tQ = new pc.Quat().setFromEulerAngles(...player._targetRotation)
          rotation.slerp(rotation, tQ, 0.1)
          player.setRotation(rotation)
        }
      })
      ID.current = window.requestAnimationFrame(step)
    }

    ID.current = window.requestAnimationFrame(step)

    return _ => window.cancelAnimationFrame(ID.current)
  }, [])
}
