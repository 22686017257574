import { connect, useDispatch } from 'react-redux'
import Button from '../../Button'
import { closePopout } from '../../../redux/site'
import Image from 'next/image'

const LeftGroup = ({ group, onRequestCreateGroup, onRequestRejoinGroup }) => {
  return (
    <div className='space-y-6 p-6 md:px-8 md:py-10'>
      <div className='mx-auto relative w-10/12 h-72'>
        <Image layout='fill' objectFit='cover' src='/images/nature-man-colour.svg' />
      </div>
      <h1 className='font-display text-3xl font-semibold text-gray-700'>You've left the group?</h1>
      <p>If you want to join the group again you'll need the invite link again?</p>

      <div className='flex justify-center mx-auto space-x-4'>
        <div className='text-red-300 tracking-xl hover:bg-red-50 cursor-pointer px-4 py-3 rounded uppercase text-sm font-bold font-detail tracking-widest' onClick={onRequestRejoinGroup}>Rejoin</div>
        <Button onClick={onRequestCreateGroup}>Create new group </Button>
      </div>
    </div>
  )
}

const mapStateToProps = ({  }, ownProps) => ({ ...ownProps })
export default connect(mapStateToProps, {  })(LeftGroup)
