import { connect } from 'react-redux'
import UserIcon from '../../user/icon'
import { toggleMicrophone } from '../../../redux/groups'
import { BiMicrophone, BiMicrophoneOff } from 'react-icons/bi'
import { RiSettings3Fill } from 'react-icons/ri'
import { FiUserPlus } from 'react-icons/fi'
import { IoChatbubblesSharp } from 'react-icons/io5'
import { MdCallEnd, MdContentCopy } from 'react-icons/md'
import { toast } from 'react-toastify'

const Participant = ({ clientId, user, isAudioEnabled, isCurrentUser }) => (
  <div className='flex items-center space-x-4'>
    <UserIcon account={user.userId} size={32} className='shadow' />
    <p className='font-medium capitalize flex-grow'>{`${user.nickname}${isCurrentUser ? ' (You)' : ''}` }</p>
    {isAudioEnabled ? <BiMicrophone size='1.5rem' className='text-gray-300' /> : <BiMicrophoneOff size='1.5rem' className='text-gray-300' />}
  </div>
)

const CallButton = ({ children, onClick, label }) => {
  return (
    <div onClick={onClick} className='group flex items-center justify-center rounded-full bg-red-100 opacity-80 hover:bg-red-300 cursor-pointer w-11 h-11'>
      {children}
      <span className='opacity-0 group-hover:opacity-100 absolute transform -translate-y-8 text-xs px-2 py-0.5 font-detail font-semibold text-red-300 uppercase tracking-widest'>{label}</span>
    </div>
  )
}

const GroupCall = ({ group, auth, room, onRequestSettings, onRequestLeaveGroup, onRequestInviteUser, toggleMicrophone }) => {
  const copyShareLink = _ => {
    navigator.clipboard.writeText(`https://${window.location.host}/join/${group}`)
    toast('Invite link Copied!')
  }
  return (
    <div className='w-full h-full flex flex-col text-left divide-y'>
      <div className='group p-6 py-6 md:px-8 cursor-pointer' onClick={copyShareLink}>
        <h2 className='text-xs tracking-wide text-gray-300'>Group Name</h2>
        <div className='flex space-x-2'>
          <h1 className='font-display text-2xl font-semibold capitalize'>{ group.split('-').join(' ') }</h1>
          <MdContentCopy size='1rem' className='text-white group-hover:text-gray-400' />
        </div>

      </div>
      <div className='flex-grow flex flex-col p-6 py-6 md:px-8 space-y-4'>
        <div className='flex text-gray-300'>
          <p className='flex-grow'>In the group</p>
          <p>{room.participants ? room.participants.length : 0}/5</p>
        </div>
        {room.participants && room.participants.map(participant => <Participant key={participant.clientId} {...participant} isCurrentUser={participant.user.userId === auth.user.uid} />)}
        {(!room.participants || room.participants.length < 5) && (
          <div onClick={onRequestInviteUser} className='space-x-2 group flex items-center cursor-pointer'>
            <FiUserPlus size='1.5rem' className='text-red-300 group-hover:text-red-400 m-2' />
            <p className='text-white text-sm group-hover:text-red-200 '>Invite someone</p>
          </div>
        )}
        {room.participants && room.participants.length === 1 && (
          <div className='w-full relative flex items-center flex-grow'>
            <div className='absolute m-auto text-gray-300 text-center'>
              <p className='w-2/3 mx-auto'>It looks like you're all alone. Why not invite some friends?</p>
            </div>
            <IoChatbubblesSharp size='14rem' className='block m-auto text-gray-100' />
          </div>
        )}
      </div>
      <div className='flex space-x-4 p-6 py-6 md:px-8 items-center'>
        <div className='relative flex-shrink flex items-center group'>
          <RiSettings3Fill onClick={onRequestSettings} size='2rem' className='text-red-200 group-hover:text-red-300 cursor-pointer' />
          <span className='opacity-0 group-hover:opacity-80 absolute transform -translate-y-7 -translate-x-1/4 text-xs px-2 py-0.5 font-detail uppercase font-semibold text-red-300 tracking-widest'>Settings</span>
        </div>
        <div className='flex space-x-6 flex-grow w-full mx-auto justify-end'>
          <CallButton onClick={toggleMicrophone} label={room.isAudioEnabled ? 'Unmute' : 'Mute'}>
            {room.isAudioEnabled ? <BiMicrophone size='1.6rem' className='text-red-400 group-hover:text-white' /> : <BiMicrophoneOff size='1.6rem' className='text-red-400 group-hover:text-white' />}
          </CallButton>

          <CallButton onClick={onRequestLeaveGroup} label='End'>
            <MdCallEnd size='1.6rem' className='text-red-400 group-hover:text-white' />
          </CallButton>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ groups, auth, ownProps }) => ({ ...ownProps, auth, room: groups })
export default connect(mapStateToProps, { toggleMicrophone })(GroupCall)
