import { connect } from 'react-redux'
import Popout from '../Popout'
import Welcome from './states/Welcome'
import MoveAround from './states/MoveAround'
import GetMoving from './states/GetMoving'
import { forwardRef, useContext, useState } from 'react'
import Button from '../Button'
import Interacting from './states/Interacting'
import { closePopout, completeOnboarding } from '../../redux/site'
import OnboardingSuccess from './states/OnboardingSuccess'
import { CgClose } from 'react-icons/cg'
import isMobile from 'is-mobile'
import LikeAController from './states/LikeAController'

const Onboarding = ({ completeOnboarding, closePopout, isHovering, forwardRef }) => {
  const [frame, setFrame] = useState({ id: 0, hasCompletedUserAction: true })

  const frames = isMobile(navigator.userAgent, true, true)
    ? [Welcome, LikeAController, Interacting, OnboardingSuccess] // Mobile flow
    : [Welcome, MoveAround, GetMoving, Interacting, OnboardingSuccess] // Desktop flow
  const getFrame = id => ({ id, hasCompletedUserAction: frames[id].hasCompletedUserAction })
  const OnboardingFrame = frames[frame.id]

  const advanceFrame = _ => {
    if (OnboardingFrame === Interacting) completeOnboarding()
    setFrame(getFrame(frame.id + 1))
  }

  const skipToTheEnd = _ => setFrame(getFrame(frames.length - 1))
  const completeUserAction = _ => {
    const currentFrame = getFrame(frame.id)
    currentFrame.hasCompletedUserAction = true
    setFrame(currentFrame)
    advanceFrame()
  }


  const closeAndSkipOnboarding = _ => {
    completeOnboarding()
    closePopout()
  }

  return (
    <Popout className='flex relative flex-col md:bg-opacity-90 pointer-events-auto justify-center items-center text-center space-y-6 p-10 w-full h-screen md:w-112 md:h-152 lg:w-128 lg:h-160 max-h-screen' ref={forwardRef}>
      <CgClose size='1.5rem' onClick={closeAndSkipOnboarding} className='absolute top-6 right-6 md:top-4 md:right-4 cursor-pointer text-red-300 hover:text-red-400' />
      <OnboardingFrame isHovering={isHovering} onComplete={completeUserAction} />
      {OnboardingFrame !== OnboardingSuccess && <>
        <div className='flex space-x-3 justify-center'>
          {frames.slice(0, -1).map((frm, idx) => (
            <div key={idx} className={'rounded-full w-2 h-2 bg-red-100 ' + (OnboardingFrame === frm ? 'bg-red-300' : '')} />
          ))}
        </div>
        {
          (isMobile(navigator.userAgent, true, true) || frame.hasCompletedUserAction) && <>
            <Button className='w-full max-w-xs' onClick={advanceFrame}>
              Next
            </Button>
            <p className='text-xs tracking-widest font-detail text-gray-400 text-center underline uppercase' onClick={skipToTheEnd}>
              Skip
            </p>
          </>
        }
      </>}
      {OnboardingFrame === OnboardingSuccess && (
        <Button className='w-full max-w-xs mt-8' onClick={closeAndSkipOnboarding}>
          Ok
        </Button>
      )}
    </Popout>
  )
}

const ConnectedOnboarding = connect(_ => _, { closePopout, completeOnboarding })(Onboarding)
export default forwardRef((props, ref) => <ConnectedOnboarding {...props} forwardRef={ref} />)