import Image from 'next/image'
import Directions from '../../../../public/images/directions-colour.svg'

const Welcome = ({ onComplete }) => {
  return (
    <>
      <div className='relative w-full h-1/3'>
        <Image layout='fill' src={Directions} />
      </div>
      <h1 className='font-display text-4xl font-semibold text-gray-700'>Welcome!</h1>
      <p>If you've not been here before, it's worth taking the time to get familiar with the controls.</p>
    </>
  )
}

Welcome.defaultProps = {
  onComplete: _ => console.log('onComplete is not overridden')
}

Welcome.hasCompletedUserAction = true

export default Welcome
