/* global pc */

import { resolve } from 'path-browserify'
import { loadModules } from './load-wasm-modules'

export const createEngine = async (canvas, config) => {
  // const pc = await import('../../utils/_engine')
  const pc = await import('playcanvas')
  pc.script.createLoadingScreen = _ => _
  pc._IS_PROD = true
  window.pc = pc

  return new Promise((resolve, reject) => {
    const { scenes, assets, settings } = config
    const { ASSET_PREFIX, SCRIPT_PREFIX, CONTEXT_OPTIONS, SCRIPTS, INPUT_SETTINGS, PRELOAD_MODULES } = settings

    pc.script.legacy = false

    const app = new pc.Application(canvas, {
      elementInput: new pc.ElementInput(canvas, {
        useMouse: INPUT_SETTINGS.useMouse,
        useTouch: INPUT_SETTINGS.useTouch
      }),
      keyboard: INPUT_SETTINGS.useKeyboard ? new pc.Keyboard(window) : null,
      mouse: INPUT_SETTINGS.useMouse ? new pc.Mouse(canvas) : null,
      gamepads: INPUT_SETTINGS.useGamepads ? new pc.GamePads() : null,
      touch: INPUT_SETTINGS.useTouch && pc.platform.touch ? new pc.TouchDevice(canvas) : null,
      graphicsDeviceOptions: CONTEXT_OPTIONS,
      assetPrefix: ASSET_PREFIX || '',
      scriptPrefix: SCRIPT_PREFIX || '',
      scriptsOrder: SCRIPTS || []
    })

    // set PC ref
    app.pc = pc

    // KLUDGE : Have to pollute global namespace
    window.ASSET_PREFIX = ASSET_PREFIX
    window.PRELOAD_MODULES = PRELOAD_MODULES
    // window.config = config

    const reflow = function () {
      // const pixelRatio = devicePixelRatio || 1
      // app.resizeCanvas(canvas.width, canvas.height)
      // debugger
      app.resizeCanvas(window.innerWidth, window.innerHeight)
      // app.setCanvasResolution(window.innerWidth, window.innerHeight)
      canvas.style.width = ''
      canvas.style.height = ''

      // var fillMode = app._fillMode
      // debugger
      // if (fillMode === pc.FILLMODE_NONE || fillMode === pc.FILLMODE_KEEP_ASPECT) {
      //   if (
      //     (fillMode === pc.FILLMODE_NONE && canvas.clientHeight < window.innerHeight) ||
      //     canvas.clientWidth / canvas.clientHeight >= window.innerWidth / window.innerHeight
      //   ) {
      //     canvas.style.marginTop = Math.floor((window.innerHeight - canvas.clientHeight) / 2) + 'px'
      //   } else {
      //     canvas.style.marginTop = ''
      //   }
      // }
    }

    const configureCss = function (fillMode, width, height) {
      // Configure resolution and resize event
      if (canvas.classList) {
        canvas.classList.add('fill-mode-' + fillMode)
      }

      // css media query for aspect ratio changes
      let css = '@media screen and (min-aspect-ratio: ' + width + '/' + height + ') {'
      css += '    #application-canvas.fill-mode-KEEP_ASPECT {'
      css += '        width: auto;'
      css += '        height: 100%;'
      css += '        margin: 0 auto;'
      css += '    }'
      css += '}'

      // append css to style
      if (document.head.querySelector) {
        let style = document.head.querySelector('style')
        if (!style) {
          style = document.createElement('style')
          document.head.appendChild(style)
        }
        style.innerHTML += css
      }
    }

    const configure = function () {
      app._parseApplicationProperties(config.application_properties, function (err) {
        app._parseScenes(scenes)
        app._parseAssets(assets)
        if (!err) resolve(app)
        if (err) reject(err)
      })

      configureCss(app._fillMode, app._width, app._height)

      // do the first reflow after a timeout because of
      // iOS showing a squished iframe sometimes
      setTimeout(function () {
        reflow()
        window.addEventListener('resize', reflow, false)
        window.addEventListener('orientationchange', reflow, false)
        // debugger
        // app.preload(function (err) {
        //   if (err) console.error(err)
        //   app.loadScene(scenes[0].url, function (err, scene) {
        //     if (err) console.error(err)
        //     app.start()
        //   })
      })
    }

    if (PRELOAD_MODULES.length > 0) {
      loadModules(PRELOAD_MODULES, ASSET_PREFIX, configure)
    } else {
      configure()
    }
  })

  // }, [config] )

  // return app
}

export const loadSceneData = (app, sceneItem) => {
  return new Promise((resolve, reject) => {
    app.scenes.loadSceneData(sceneItem, err => {
      if (err) reject(err)
      else resolve(sceneItem)
    })
  })
}

export const loadAsset = (app, asset) => {
  return new Promise((resolve, reject) => {
    asset.once('load', asset => resolve(asset))
    asset.once('error', error => reject(error))
    app.assets.load(asset)
  })
}

/**
 * Preloads the assets of a scene that are tagged by the scene name
 *
 * @param {Application} app An instance of the game engine
 * @param {String} sceneName The name of the scene to load
 */
export const preloadScene = async function (app, sceneName, onProgress = _ => _) {
  const sceneItem = app.scenes.find(sceneName)
  if (!sceneItem) throw new Error(sceneName + ' does not exist')

  await loadSceneData(app, sceneItem)

  let assets = app.assets.findByTag(sceneName, 'all')

  const previousSceneName = app._currentSceneName

  if(previousSceneName && previousSceneName !== sceneName ){

    console.log('prev scene was', previousSceneName)
    const previousSceneAssets = app.assets.findByTag(previousSceneName)

    // Any asset in the previous scene that is not part of the current scene
    const assetsToUnload = previousSceneAssets
      .filter(asset => (
        asset.type !== 'script'
      //   // asset.type === 'texture' || 
      //   // asset.type === 'cubemap' || 
      //   // asset.type === 'material' || 
      //   // asset.type === 'script' || 
      //   // asset.type === 'container' || 
      //   // asset.type === 'shader'
      ))
      .filter(asset => !asset.tags.has(sceneName))
      .filter(asset => !asset.tags.has('all'))

    console.log('removing assets', assetsToUnload)
    assetsToUnload.forEach(asset => {
      asset.unload()
      // asset.loading = false;
    })

    // Remove any existing assets already available from the load queue
    // console.log('all assets', sceneName, assets.slice())
    assets = assets.filter(asset => !asset.tags.has(previousSceneName))
    assets = assets.filter(asset => !asset.tags.has('all'))
    // console.log('filtereted assets', sceneName, assets.slice())

  }

  app._currentSceneName = sceneName

  assets = assets.filter(asset => !asset.loaded)
  
  // Handle progress reporting
  onProgress(0)
  let numLoadedAssets = 0
  const loadedAssets = []
  const emitProgress = asset => {
    loadedAssets.push(asset)
    onProgress(++numLoadedAssets / assets.length)
    // console.log(asset, numLoadedAssets, loadedAssets)
  }
  assets.forEach(asset => asset.once('load', emitProgress))
  assets.forEach(asset => asset.once('error', _ => console.error( error )))

  // Load assets
  console.log('Loading Assets: ', assets)
  console.log('Already loaded Assets: ', )
  const assetList = assets.map(asset => loadAsset(app, asset))

  // Ensure all loaded
  const allLoadedAssets = await Promise.all(assetList)

  return sceneItem


        // const assetListLoader = new pc.AssetListLoader(assets.map(asset => asset.id), app.assets)
        // assetListLoader.on('progress', asset => onProgress(++numLoadedAssets / assets.length))

        //There's a bug in the engine causing a race condition (https://forum.playcanvas.com/t/bug-in-assetlistloader/20834/3). This should fix it
        // assetListLoader.load = function(done, scope) {
        //   var i = 0;
        //   var l = this._assets.length;
        //   var asset;
  
        //   // this._total = l;
        //   this._count = 0;
        //   this._failed = [];
        //   this._callback = done;
        //   this._scope = scope;
  
        //   this._registry.on("load", this._onLoad, this);
        //   this._registry.on("error", this._onError, this);

        //   this._total = this._assets.filter(asset => !asset.loading && !asset.loaded).length
        //   for (i = 0; i < l; i++) {
        //       asset = this._assets[i];
  
        //       if (!asset.loading && !asset.loaded) {
        //           this._registry.load(asset);
        //       }
        //   }
        // }

        // assetListLoader.ready(loadedAssets => {
        //   // Sometimes the 'progress' event isn't called for all assets. So manually call onProgress when we know all have loaded
        //   onProgress(1)
        //   resolve(scene)
        // })

        // assetListLoader.load(err => {
        //   if (err){
        //     console.log('Error', err)
        //     reject(err)
        //   }
        // })
  //     }
  //   })
  // })
}

/**
 * Given an engine instance and a scene file url, this functuin
 * will load a new scene into the engine, removing any previous
 * scene that was currently in use.
 *
 * @param {Application} app
 * @param {String} url Url of the scene file to to be loaded
 */
export const loadScene = (app, scene) => {
  return new Promise((resolve, reject) => {
    // const settings = scene.data.settings
    // app.loader.clearCache(scene.url, 'hierarchy')

    // app.scenes.loadSceneHierarchy( scene, (err, scene) => {

    //   console.log(err, scene)
    //   if(err) reject(err)
    //   else {
    //     app.applySceneSettings(settings)

    //     if (!app.started) {
    //       app.started = true
    //       console.log('EBNGINE STARTED')
    //       app.start()
    //     }

    //     resolve(scene)
    //   }

    // })

    // app.loader.clearCache(scene.url, 'hierarchy')

    // Remove any existing scenes
    const previousScene = app.root.findByName('Root')
    if (previousScene) previousScene.destroy()

    app.scenes.loadSceneHierarchy(scene, err => {
      if (err) reject(err)
      else {
        app.applySceneSettings(scene.data.settings)
        resolve(scene)
        if (!app.started) {
          app.started = true
          app.start()
        }
      }
    })
  })
}
