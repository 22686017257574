import Image from 'next/image'
import { useContext } from 'react'
import { useEngineEvent } from '../../../hooks/game-engine/useEngine'
import { EngineContext } from '../../../layouts/GameLayout'

const Interacting = ({ onComplete }) => {

  const { engine } = useContext(EngineContext)
  useEngineEvent(engine, 'wayfinding:click', onComplete)
  useEngineEvent(engine, 'ui:onClick', onComplete)
  useEngineEvent(engine, 'sceneManager:loadScene', onComplete)

  return (
    <>
      <div className='relative w-full h-1/3'>
        <Image layout='fill' src='/images/choices-colour.svg' />
      </div>
      <h1 className='font-display text-3xl font-semibold text-gray-700'>Interacting?</h1>
      <p>You can find more information on items including artwork by simply clicking on them.</p>
      <p>Finding an interactive item and click it!</p>
    </>
  )
}

Interacting.hasCompletedUserAction = false

export default Interacting
