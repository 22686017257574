import { useEffect, useRef } from 'react'
import { MicrophoneIcon } from '@heroicons/react/solid'
import ListBox from './Listbox'
import { useAudioInputDevices } from './MediaDeviceListBox'

const MicrophoneTest = ({ device }) => {
  const animRef = useRef()
  const deviceId = device && device.deviceId

  useEffect(_ => {
    let ID

    navigator.mediaDevices.getUserMedia({ audio: { deviceId }, video: false })
      .then(stream => {
        const context = new window.AudioContext()
        const gainNode = context.createGain()
        const src = context.createMediaStreamSource(stream)
        src.connect(gainNode)
        gainNode.connect(context.destination)
        gainNode.gain.setTargetAtTime(0, context.currentTime, 0)
        const analyser = context.createAnalyser()

        src.connect(analyser)
        gainNode.connect(analyser)
        analyser.fftSize = 64
        const bufferLength = analyser.frequencyBinCount
        const dataArray = new Uint8Array(bufferLength)

        function renderFrame () {
          ID = window.requestAnimationFrame(renderFrame)
          analyser.getByteFrequencyData(dataArray)
          const max = Math.max(...dataArray)
          if (animRef.current) animRef.current.style.opacity = max / 255
        }

        renderFrame()
      })

    return _ => ID && window.cancelAnimationFrame(ID)
  }, [device && device.deviceId])

  return (
    <div className='relative w-8 h-8'>
      <div ref={animRef} className='absolute w-8 h-8 rounded-full bg-red-300' />
      <MicrophoneIcon className='absolute w-5 h-5 m-1.5 inline text-gray-300' />
    </div>
  )
}

const AudioInputList = _ => {
  const [inputDevices, selectedInputDevice, selectInputDevice] = useAudioInputDevices()
  
  return (
    <>
      <h1 className='font-medium'>Microphone</h1>
      <div className='flex items-center space-x-4'>
        <ListBox items={inputDevices} selectedItem={selectedInputDevice} setSelected={selectInputDevice} />
        <MicrophoneTest device={selectedInputDevice} />
      </div>
    </>
  )
}

export default AudioInputList
