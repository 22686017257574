import Button from './Button'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Listbox, Switch, Transition } from '@headlessui/react'
import { VolumeUpIcon } from '@heroicons/react/solid'
import ListBox from './Listbox'
import { useAudioInputDevices, useAudioOutputDevices } from './MediaDeviceListBox'

const AudioTest = ({ device }) => {

  const deviceId = device && device.deviceId
  const audio = useMemo(_ => {
    const audio = document.createElement('audio')
    audio.src = '/sound/accent.mp3'
    audio.autoplay = false
    return audio
    // document.body.append(audio)
  })

  useEffect(_ => {
    // audio.srcObject = mediaStreamNode.stream
    audio.setSinkId && audio.setSinkId(deviceId)
  }, [deviceId])

  return <div className='relative w-8 h-5' >
    <VolumeUpIcon className='absolute h-5 w-5 inline text-gray-300 hover:text-red-300 cursor-pointer' onClick={_ => audio.play()}/>
  </div>
}

const AudioOutputList = _ => {

  const [outputDevices, selectedOutputDevice, selectOutputDevice] = useAudioOutputDevices()


  return <>
    <h1 className='font-medium'>Speakers</h1>
    <div className='flex items-center space-x-6'>
      <ListBox items={outputDevices} selectedItem={selectedOutputDevice || {label: 'Default Output'}} setSelected={selectOutputDevice} />
      <AudioTest device={selectedOutputDevice}/>
    </div>
  </>
}

export default AudioOutputList