/* global pc */

import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { createEngine, loadScene, preloadScene } from './engine'

const SWROpts = { revalidateOnFocus: false, revalidateOnReconnect: false }

/**
 * Creates a new or fetches a cached game engine given a specific canvas
 *
 * @param {Object} config - Engine comfiguration file
 * @param {ReactCanvasElement} canvas
 */
export const useEngine = (config, canvas) => {
  return useSWR(
    _ => [config.application_properties.version],
    _ => {
      // Given a canvas, return the Engine if one exists
      const app = typeof pc !== 'undefined' && pc.Application.getApplication(canvas.current.id)
      return app || createEngine(canvas.current, config)
    },
    SWROpts
  )
}

/**
 * Loads a new scene into the engine, and runs it
 *
 * @param {Engine} app - Engine
 * @param {String} name - Name of the scene to load
 */
export const useScene = (app, scene) => {
  return useSWR(scene && [scene, scene.url], scene => loadScene(app, scene), { ...SWROpts, shouldRetryOnError: false })
}

/**
 * Preloads all assets tagged with the scene name
 *
 * @param {Engine} app - Engine
 * @param {String} name - Name of the scene to load
 */
export const usePreloadScene = (app, name) => {
  const [progress, emitProgress] = useState(0)
  const { data, error } = useSWR(app && name && [name], name => preloadScene(app, name, emitProgress), {
    ...SWROpts,
    shouldRetryOnError: false
  })
  return { data, error, progress }
}

/**
 *
 * @param {String} e - Event name to listen to
 * @param {Function} callback - Callback
 */
export const useEngineEvent = (app, e, callback, blocks = []) =>
  useEffect(_ => {
    if (e === 'player:broadcast') console.log('listening to event', e)
    if (app) {
      if (e === 'player:broadcast') console.log('listening to event', e)
      app.on(e, callback)
    }
    return _ => {
      if (app) app.off(e, callback)
    }
  }, [...blocks, app])
