import { useEffect, useRef, useState } from 'react'
import getSendbird from '../utils/sendbird'
import ListBox from './Listbox'

export const useAudioInputDevices = _ => {
  const [audioInputs, setAllAudioInputs] = useState(null)
  const sb = useRef(null)
  const [selectedDevice, setSelectedDevice] = useState(null)

  useEffect(_ => {
    const updateDevices = async _ => {
      sb.current = await getSendbird()
      sb.current.useMedia({ audio: true })
      const allAudioInputDevices = sb.current.getAvailableAudioInputDevices()

      sb.current.addListener('on-audio-inputs-change', { 
        onAudioInputDeviceChanged: devices => {
          setSelectedDevice(sb.current.getCurrentAudioInputDevice())
          setAllAudioInputs(devices)
        }
      })
      setSelectedDevice(sb.current.getCurrentAudioInputDevice())
      setAllAudioInputs(allAudioInputDevices)
    }

    updateDevices()
    return _ => sb.current && sb.current.removeListener('on-audio-inputs-change')
  })

  const setAudioDevice = mediaDevice => {
    if (sb.current) {
      setSelectedDevice(mediaDevice)
      sb.current.selectAudioInputDevice(mediaDevice)
    }
  }
  return [audioInputs, selectedDevice, setAudioDevice]
}

export const useAudioOutputDevices = _ => {

  const [audioOutputs, setAllAudioOutputs] = useState(null)
  const sb = useRef(null)
  const [selectedDevice, setSelectedDevice] = useState(null)

  useEffect(_ => {
    const updateDevices = async _ => {
      sb.current = await getSendbird()
      sb.current.useMedia({ audio: true })
      const allAudioOutputDevices = sb.current.getAvailableAudioOutputDevices()      

      sb.current.addListener('on-audio-output-change', { onAudioOutputDeviceChanged: devices => {
        setSelectedDevice(sb.current.getCurrentAudioOutputDevice())
        setAllAudioOutputs(devices)
      }})
      setSelectedDevice(sb.current.getCurrentAudioOutputDevice())
      setAllAudioOutputs(allAudioOutputDevices)
    }

    updateDevices()
    return _ => sb.current && sb.current.removeListener('on-audio-output-change')
  })

  const setAudioDevice = mediaDevice => {
    if(sb.current){
      setSelectedDevice(mediaDevice)
      sb.current.selectAudioOutputDevice(mediaDevice)
    }
  }
  return [audioOutputs, selectedDevice, setAudioDevice]
}

const MediaDeviceListBox = ({ type, children }) => {

  const [devices, selectedDevice, selectDevice] = type === 'input' 
    ? useAudioInputDevices() 
    : useAudioOutputDevices()

    
    if(!selectedDevice || !devices) return null

  return <ListBox items={devices} itemKey='deviceId' selectedItem={selectedDevice} setSelected={selectDevice}>
    { children }
  </ListBox>
}

export default MediaDeviceListBox