import Image from 'next/image'
import { useEffect, useState } from 'react'

const Key = ({ keyCode, keyDown, altCode }) => {
  return (
    <b className={`p-2 px-3 w-4 h-4 font-medium text-xl rounded uppercase animate border ${(keyDown === keyCode || keyDown === altCode) ? 'bg-red-400 text-white shadow-md bounce border-transparent' : 'bg-white border-red-300 text-red-300 shadow'}`}>
      {keyCode}
    </b>
  )
}

const GetMoving = ({ onComplete }) => {
  const [keyDown, setKeyDown] = useState({ key: null, count: 0 })

  const codes = [
    ['w', 'ArrowUp'],
    ['a', 'ArrowLeft'],
    ['s', 'ArrowDown'],
    ['d', 'ArrowRight']
  ]

  useEffect(_ => {
    const onKeyDown = ({ key }) => {
      setKeyDown(state => ({ ...state, key }))
    }

    const onKeyUp = ({ key }) => {
      if (!codes.flat().includes(key)) return
      setKeyDown(({ count }) => ({ count: count + 1, key: null }))
    }
    window.document.addEventListener('keydown', onKeyDown)
    window.document.addEventListener('keyup', onKeyUp)
    // && (domRef.current.className += ' bg-red-400')
    return _ => {
      window.document.removeEventListener('keydown', onKeyDown)
      window.document.removeEventListener('keyup', onKeyUp)
    }
  }, [])

  if (keyDown.count === 10) {
    onComplete()
  }

  return (
    <>
      <div className='relative w-full h-1/3'>
        <Image layout='fill' src='/images/sprinter-colour.svg' />
      </div>
      <h1 className='font-display text-3xl font-semibold text-gray-700'>Get Moving?</h1>
      <p>
        You can move around by pressing the <b>W</b> <b>A</b> <b>S</b> <b>D</b> or arrow keys on your keyboard.
      </p>
      <p>
        Give this a try now.
      </p>
      <div className='space-x-6 py-6'>
        {
          codes.map(code => <Key key={code[0]} keyCode={code[0]} altCode={code[1]} keyDown={keyDown.key} />)
        }
      </div>
    </>
  )
}

GetMoving.hasCompletedUserAction = false

export default GetMoving
