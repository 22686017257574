import { connect } from 'react-redux'
import Button from '../../Button'
import { connectToMetamask } from '../../../redux/auth'
import ModelViewer from '@metamask/logo'
import { useEffect, useRef } from 'react'

const ConnectMetamask = ({ connectToMetamask }) => {
  const logoRef = useRef()

  useEffect(_ => {
    const viewer = ModelViewer({
      // Dictates whether width & height are px or multiplied
      pxNotRatio: true,
      followMotion: true,
      width: '100%',
      height: 'auto',
      // pxNotRatio: false,
      // width: 0.9,
      // height: 0.9,

      // To make the face follow the mouse.
      followMouse: true,

      // head should slowly drift (overrides lookAt)
      slowDrift: true
    })
    // add viewer to dom
    logoRef.current.appendChild(viewer.container)
    return _ => {
      if (logoRef.current && viewer.container) logoRef.current.removeChild(viewer.container)
      viewer.stopAnimation()
    }
  }, [])

  return (
    <div className='my-auto flex items-center h-full'>
      <div className='space-y-8'>
        <div className='w-full' ref={logoRef} />
        <h1 className='font-display text-3xl font-semibold text-gray-700'>Get Connected</h1>
        {/* <p>MetaMask provides the simplest yet most secure way to connect to blockchain-based applications, such as The Crypt.</p> */}
        <p>You'll need to connect your wallet to the site in order to purchase items and create an account</p>
        <Button className='w-full' onClick={_ => connectToMetamask()}>
          Connect Metamask
        </Button>
        <p>
          <a className='text-xs font-detail underline' href='#' onClick={_ => window && window.Beacon && window.Beacon('article', '60f6cc4cb37d837a3d0db6cc', { type: 'modal' })}>
            What's a wallet?
          </a>
        </p>
      </div>
    </div>
  )
}

export default connect(_ => _, { connectToMetamask })(ConnectMetamask)
