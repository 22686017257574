import { connect } from 'react-redux'
import Button from '../../Button'
import { createGroup, getRoom, joinGroup } from '../../../redux/groups'
import { setFirstPersonMode } from '../../../redux/site'
import useSWR from 'swr'
import Spinner from '../../spinner'
import { RefreshIcon } from '@heroicons/react/solid'

const CreateGroup = ({ group, createGroup, joinGroup }) => {

  const { data, mutate } = useSWR('/api/random-words')
  const { data: existingRoom } = useSWR(data && data.words, getRoom)

  const createAndJoinGroup = async id => {
    if (existingRoom || !id) return
    await createGroup(id)
    await joinGroup(id)
    setFirstPersonMode()
  }

  const refreshWords = _ => mutate()

  return (
    <>
      <div className='space-y-4 px-6 md:px-8'>
        <h1 className='font-display text-3xl font-semibold'>Create a group</h1>
        <p>Create a group of up to 5 other friends to explore exhibitions. You'll be notified as soon as they join and you’ll be able to see their avatar and chat to them.</p>
      </div>
      <div className='space-y-2 w-full px-6 md:px-8'>
        <p className='text-sm text-gray-400'>Your group name</p>
        <div className='w-full font-display text-2xl text-center text-red-300 rounded-lg border-2 border-gray-300 p-4 px-2 flex justify-center items-center'>
          {data ? <div className='flex-grow'>{data.words}</div> : <div className='flex justify-center flex-grow'><Spinner /></div>}
          <RefreshIcon onClick={refreshWords} className='w-6 h-6 mr-2 text-red-200 hover:text-red-300 cursor-pointer flex-shrink place-content-end' />
        </div>
        {existingRoom && <p>That group name is already taken. Try another one</p>}
        <p className='text-xs font-detail text-gray-400 '>No spaces or special characters allowed</p>
      </div>
      <div className='space-y-2 text-center flex flex-col items-center px-6 md:px-8 w-full'>
        <Button className='w-full max-w-xs' disabled={!!existingRoom} onClick={_ => createAndJoinGroup(data.words)}>
          Create Group
        </Button>
        <p><a href='#' onClick={_ => window.Beacon && window.Beacon('article', '60f6cd3cb55c2b04bf6d602b', { type: 'modal' })} className='text-xs font-detail text-gray-400 underline'>Learn more about Groups</a></p>
      </div>
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({ ...ownProps })
export default connect(mapStateToProps, { createGroup, joinGroup, setFirstPersonMode })(CreateGroup)
