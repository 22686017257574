import Image from 'next/image'

const LikeAController = ({ onComplete }) => {
  return (
    <>
      <div className='relative w-full h-1/3'>
        <Image layout='fill' src='/images/gameboy-colour.svg' />
      </div>
      <h1 className='font-display text-3xl font-semibold text-gray-700'>How do I move around?</h1>
      <p>Use your device like a game controller.</p>
      <p>The right hand side controls your aim. And the left hand side controls your movement.</p>
      {/* <p>Try this now.</p> */}
    </>
  )
}

LikeAController.defaultProps = {
  onComplete: _ => console.log('onComplete is not overridden')
}

LikeAController.hasCompletedUserAction = true

export default LikeAController
