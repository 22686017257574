import { connect } from 'react-redux'
import Popout from '../Popout'
import InstallMetamask from './states/InstallMetamask'
import ConnectMetamask from './states/ConnectMetamask'
import CaptureEmailUsername from './states/CaptureEmailUsername'
import UnsupportedBrowser from './states/UnsupportedBrowser'
import AuthSuccess from './states/AuthSuccess'
import { CgClose } from 'react-icons/cg'
import { closePopout } from '../../redux/site'
import useSWR from 'swr'
import SignIn from './states/SignIn'
import { forwardRef } from 'react'
import { detect } from 'detect-browser'
import { useRouter } from 'next/router'
import isMobile from 'is-mobile'

const Authenticate = ({ auth, closePopout, forwardRef }) => {
  // Check to see if user has an account
  const { data: user } = useSWR(_ => `/api/user/${auth.account}`, { revalidateOnMount: true })
  const router = useRouter()
  if (!user) return null
  if (auth.user && auth.continuePath) router.push(auth.continuePath)

  const browser = detect()
  const isIOS = window && (/iPad|iPhone|iPod/.test(window.navigator.platform) || (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1))

  return (
    <Popout className='flex relative flex-col pointer-events-auto justify-center items-center text-center space-y-6 p-10 w-full h-screen md:w-112 md:h-152 lg:w-128 lg:h-160 max-h-screen' ref={forwardRef}>
      <CgClose size='1.5rem' onClick={_ => closePopout()} className='absolute top-6 right-6 md:top-4 md:right-4 cursor-pointer text-red-300 hover:text-red-400' />
      {user && (
        ((browser.name === 'safari' || isIOS || isMobile()) && <UnsupportedBrowser />) || // User is on an unsupported browser
        (!auth.isWalletAvailable && <InstallMetamask />) || // User does not have metamask installed
        (!auth.account && <ConnectMetamask />) || // User has metamask but it is not connected
        (!auth.user && !user.exists && <CaptureEmailUsername />) || // User has connected metamask, but has not created an account
        (!auth.user && user.exists && <SignIn />) || // User has an account but is not signed in
        (auth.user && <AuthSuccess />) // User has an account and has successfully signed in
      )}
    </Popout>
  )
}

const mapStateToProps = ({ auth }, ownProps) => ({ ...ownProps, auth })
const ConnectedAuthenticate = connect(mapStateToProps, { closePopout })(Authenticate)
export default forwardRef((props, ref) => <ConnectedAuthenticate {...props} forwardRef={ref} />)
