import { connect, useDispatch } from 'react-redux'
import Button from '../../Button'
import { closePopout } from '../../../redux/site'
import useSWR from 'swr'
import { useEffect, useState } from 'react'
import getSendbird from '../../../utils/sendbird'
import usePermissions from '../../../hooks/device/usePermissions'
import Image from 'next/image'
import useForceUpdate from '../../../hooks/useForceUpdate'

const RequestPermissions = ({ group, onPermissionsGranted }) => {
  const permissions = usePermissions('microphone')
  const [devices, setDevices] = useState(null)
  const forceUpdate = useForceUpdate()

  const requestPermissions = async _ => {
    try {
      // const sendbird = await getSendbird()
      const devices = await navigator.mediaDevices.enumerateDevices()
      setDevices(devices)
      const media = await navigator.mediaDevices.getUserMedia({ audio: true })
      console.log(media)
      window.localStorage.setItem('permissions', 'granted')
      // if(mediaAccess) {
      //   // mediaAccess.dispose()
      // onPermissionsGranted()
      // }
      forceUpdate()
    } catch (e) {
      console.log(e)
      window.localStorage.setItem('permissions', 'denied')
      forceUpdate()
    }
  }

  useEffect(_ => {
    if (permissions === 'granted' && onPermissionsGranted) onPermissionsGranted()
  }, [permissions])

  // derived state
  const microphones = devices && devices.filter(d => d.kind === 'audioinput')
  const isCapableOfFullChat = microphones && microphones.length > 0
  console.log(devices, microphones)


  return (
    <>
      <div className='relative w-full h-1/3 flex flex-col items-center'>
        <div className='relative w-full h-full'>
          <Image layout='fill' src='/images/misc/permissions.svg' />
        </div>
        { devices && !isCapableOfFullChat && <p className='absolute top-0 rounded bg-red-400 px-4 py-2 text-white w-9/12 transform -translate-y-12 text-sm font-bold'>No microphone found</p> }
      </div>
      {
        devices && !isCapableOfFullChat && <div className='px-10 space-y-6 flex flex-col items-center'>
          <h1 className='font-display text-3xl font-semibold'>Before you start...</h1>
          <p>You at least need one audio input device attached when joining a group. </p>
          <p className='font-semibold'>Please attach a camera or microphone and try again</p>
        </div>
      }
      {
        (!devices && (!permissions || permissions === 'prompt')) && <div className='px-10 space-y-6 flex flex-col items-center'>
          <h1 className='font-display text-3xl font-semibold'>Before you start...</h1>
          <p>So that other people can hear you, you’ll need to grant permissions to access you microphone and camera.</p>
          <p>Don’t worry, you can always turn these off any time</p>
          <Button className='w-full max-w-xs' onClick={_ => requestPermissions()}>
            Request Permissions
          </Button>
        </div>
      }
      { !devices && permissions === 'denied' && <div className='p-10 space-y-6 flex flex-col items-center'>
        <h1 className='font-display text-3xl font-semibold'>Permissions blocked</h1>
        <p>It looks like the permissions to access your audio device has been blocked</p>
        <p>You'll need to manually enable this in the browser setting and check permissions</p>
        <Button className='w-full max-w-xs' onClick={_ => requestPermissions()}>
          Check Permissions
        </Button>
      </div> }
      <p className='underline cursor-pointer font-detail text-sm text-gray-400 tracking-wide' href='#' onClick={_ => window.Beacon && window.Beacon('article', '60f6cd7e64a230081ba16eea', { type: 'sidebar' })} >Need some help?</p>
    </>
  )
}

export default RequestPermissions
