import { useState, useCallback, useRef } from 'react'

const useHover = _ => {

  const [value, setValue] = useState(false)
  
  // Need to use a ref to keep track of the last node passed to the callback ref,
  // so we can remove its event listeners
  const ref = useRef()
  const mouseRef = useRef([-1, -1])
  // Use a callback ref so we re-add the event listeners even if the
  // node gets unmounted/remounted

  const onMouseMove = useCallback(e => {
    mouseRef.current = [e.clientX, e.clientY]
  }, [])

  const handleMouseOver = useCallback(e => setValue(true), [])

  const handleMouseOut = useCallback(e => {
    const el = e.toElement || e.relatedTarget
    if (ref.current.contains(el)) return
    setValue(false)
  }, [])

  const callbackRef = useCallback(
    (node) => {
      if (ref.current) {
        ref.current.removeEventListener('mouseenter', handleMouseOver)
        ref.current.removeEventListener('mouseleave', handleMouseOut)
        // ref.current.removeEventListener('mouseout', )
        ref.current.observer.disconnect()
        setValue(false)
      }

      ref.current = node

      if (ref.current) {

        /*
          Mouseleave event does not fire if a child element is removed from the dom

          conside this examples : 
            Rollover header (mouseenter)
            Click to open popover panel
            Panel closes and now mouse is outside header (NO MOUSLEAVE!)

          Because of this we need to manually check for dom mutations if elements aere removed.
          There may be a better way of targeting mouse events, but i couldnt think of one
        */

        const observer = new window.MutationObserver(mutations => {
          mutations.forEach(mutation => {
            const node = mutation.removedNodes[0]
            if (node && mouseRef.current) {
              const intersectionEl = document.elementFromPoint(...mouseRef.current)
              setValue(ref.current.contains(intersectionEl))
            }
          })
        })

        const config = { attributes: true, subtree: true, childList: true, characterData: true }
        observer.observe(ref.current, config)
        ref.current.observer = observer

        ref.current.addEventListener('mouseenter', handleMouseOver)
        ref.current.addEventListener('mouseleave', handleMouseOut)
        document.addEventListener('mousemove', onMouseMove)
      }
    },
    [handleMouseOver, handleMouseOut]
  )

  return [callbackRef, value]
}

export default useHover

