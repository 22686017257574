import { useEffect, useState } from 'react'

const usePermissions = name => {
  const [permission, setPermission] = useState(null)

  useEffect(_ => {
    const getPermission = async _ => {
      // const permissionStorage = window.localStorage.getItem('permissions')
      let state = window.localStorage.getItem('permissions')

      if (state !== 'granted') {
        try {
          const query = await navigator.permissions.query({ name })
          state = query.state
        } catch (e) {
          // `permissions.query({ 'microphone' })` will fail on FF, so you need to request getUserMedia
        }
      }

      setPermission(state)
    }
    getPermission()
  })

  return permission
}

export default usePermissions
