
import { connect } from 'react-redux'
import Button from '../../Button'
import MetaMaskOnboarding from '@metamask/onboarding'
import { useEffect, useRef } from 'react'

const UnsupportedBrowser = _ => {
  return (
    <>
      <div className='flex p-8 space-x-2'>
        <img width='50%' className='transform translate-x-10' src='/images/misc/firefox.svg'/>
        <img width='50%' className='transform -translate-x-10' src='/images/misc/chrome.svg'/>
      </div>
      <h1 className='font-display text-3xl font-semibold text-gray-700'>It's not you — it's your browser</h1>
      <div>
        <p className='font-medium'>It looks like you're using a browser or device that doesn't support ethereum wallets.</p>
        <p>To get the best experience, we recommend using either <a href='https://www.google.com/chrome/' className='text-red-300 underline' target='_blank'>Google Chrome</a> or <a href='https://www.mozilla.org/firefox/' className='text-red-300 underline' target='_blank' >Firefox</a> on a desktop or laptop.</p>
      </div>
      <p className='text-xs text-gray-500'>
        <a className='underline' href='#' onClick={_ => window.Beacon && window.Beacon('article', '60f6cb466ffe270af2a90830', { type: 'sidebar' })} target='_blank' rel='noreferrer'>
          Learn more about wallets.
        </a>
      </p>
    </>
  )
}

export default UnsupportedBrowser
