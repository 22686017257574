import Image from 'next/image'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { authenticate } from '../../../redux/auth'

const SignIn = ({ authenticate, auth }) => {
  useEffect(_ => {
    authenticate()
  }, [auth.user && auth.uid])

  return (
    <>
      <div className='relative w-full h-1/3'>
        <Image layout='fill' src='/images/holding-phone-colour.svg' />
      </div>
      <h1 className='font-display text-3xl font-semibold text-gray-700'>Sign In!</h1>
      <p>You'll need Metamask to sign in. When prompted, sign the message</p>
      {/* <Button className='w-full' onClick={onRequestAuthenticate}>
        Sign In
      </Button> */}
    </>
  )
}

const mapStateToProps = ({ auth }, ownProps) => ({ auth, ...ownProps })
export default connect(mapStateToProps, { authenticate })(SignIn)
